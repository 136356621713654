

























import Vue from 'vue'
import { computed, onUnmounted, onBeforeMount } from '@vue/composition-api'
import { BAlert, BLink } from 'bootstrap-vue'
import { getAppConfigStore, getVerticalMenuStore } from '@/store'
import Ecommerce from '@/views/dashboard/ecommerce/Ecommerce.vue'

export default Vue.extend({
  components: {
    BAlert,
    BLink,
    Ecommerce,
  },
  setup() {
    const appConfigStore = getAppConfigStore()
    const verticalMenuStore = getVerticalMenuStore()

    const isVerticalMenuCollapsed = computed({
      get: () => verticalMenuStore.isVerticalMenuCollapsed,
      set: val => verticalMenuStore.updateVerticalMenuCollapsed(val),
    })

    const storedValue = isVerticalMenuCollapsed.value

    onBeforeMount(() => {
      appConfigStore.UPDATE_LAYOUT_TYPE('vertical')
      isVerticalMenuCollapsed.value = true
    })

    onUnmounted(() => {
      isVerticalMenuCollapsed.value = storedValue
    })
  },
})
